import React from "react"

export const warning = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.74315 2.09905C8.97855 0.739746 7.02147 0.739743 6.25686 2.09904L0.676551 12.0196C-0.0733809 13.3528 0.890046 15.0001 2.4197 15.0001H13.5803C15.11 15.0001 16.0734 13.3528 15.3235 12.0196L9.74315 2.09905ZM7 5.00012C7 4.44784 7.44771 4.00012 8 4.00012C8.55229 4.00012 9 4.44784 9 5.00012V8.00012C9 8.55241 8.55229 9.00012 8 9.00012C7.44771 9.00012 7 8.55241 7 8.00012V5.00012ZM9 12.0001C9 11.4478 8.55229 11.0001 8 11.0001C7.44771 11.0001 7 11.4478 7 12.0001C7 12.5524 7.44771 13.0001 8 13.0001C8.55229 13.0001 9 12.5524 9 12.0001Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
